<template>
  <terms-and-condition></terms-and-condition>
</template>
<script>
import service from "@/services/others";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import TermsAndCondition from "./Terms-and-condition.vue";

export default {
  components: {
    TermsAndCondition,
  },
  data() {
    return {
      email: "",
    };
  },
  created() {
    this.email = this.getEmail();
  },
  methods: {
    getEmail() {
      const user = JSON.parse(localStorage.userData);
      return user.email;
    },
    next() {
      this.$router.push("/dashboard");
    },
    resend() {
      service
        .sendEmailPass({ email: this.email }, true)
        .then((response) => {
          console.log(response);
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('sendLink'),
                icon: "CheckCircleIcon",
                variant: "success",
              }
            })
          })
        })
        .catch((error) => {
          console.log(error);
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('errorSendLink'),
                icon: "AlertCircleIcon",
                variant: "success",
              }
            })
          })
        })
    },
  },
};
</script>
